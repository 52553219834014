.App {
  text-align: center;
  padding: 50px;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  color: #7000FF;
  margin-bottom: 40px;
  font-size: x-large;
}

.App-description {
  color: black;
  margin-bottom: 40px;
  font-size: large;
  padding: 50px;
}

.Link-description {
  color: #7B7E8F;
  margin-bottom: 40px;
  font-size: large;
}

.App-link {
  color: #7000FF;
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
